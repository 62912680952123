<template>
    <div>
        <div v-if="!details" class="error-case">ERROR: Something went wrong</div> <!-- todo: make a better looking error case -->
        <div v-else class="lyrics-wrapper">
            <div class="header">
                <div class="top-row">
                    <div @click="$router.go(-1)" class="back-arrow highlight"><Left/></div>
                    <div class="title">{{details.name}}</div>
                    <BookmarkChecked class="highlight" v-if="bookmarked" @click="removeBookmark"/>
                    <Bookmark class="highlight" v-else @click="bookmark"/>
                </div>
                <div class="credit">{{details.credit}}</div>
                <div class="sources">
                    <div v-for="(link, platform) in details.sources" v-bind:key="platform">
                        <a class="source-link" :href="link" target="_blank" rel="noopener">
                            <div v-if="platform=='spotify'"><Spotify/></div>
                            <div v-else-if="platform=='youtube'"><Youtube/></div>
                            <div v-else-if="platform=='bandcamp'"><Bandcamp/></div>
                            <div v-else><Link/></div>
                            <div class="link-name">{{platform}}</div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="lyrics-wrap">
                <div class="title">Lyrics</div>
                <div class="lyrics-card">
                    <div v-for="(part, id) in details.verses" v-bind:key="id">
                        <div v-if="part.chorus" class="chorus">
                            <div class="chorus-indicator">Chorus</div>
                            <div class="line" v-for="(line, lineid) in details.chorus" v-bind:key="lineid">{{line}}</div>
                        </div>
                        <div v-else class="verse">
                            <div class="line" v-for="(line, lineid) in part" v-bind:key="lineid">{{line}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Left from 'vue-material-design-icons/ChevronLeft.vue';
import Bookmark from 'vue-material-design-icons/Bookmark.vue';
import BookmarkChecked from 'vue-material-design-icons/BookmarkCheck.vue';
import Spotify from 'vue-material-design-icons/Spotify.vue';
import Youtube from 'vue-material-design-icons/Youtube.vue';
import Bandcamp from 'vue-material-design-icons/Bandcamp.vue';
import Link from 'vue-material-design-icons/LinkVariant.vue';

export default {
    props: ['filename', 'versionID'],
    components: {
        Left,
        Bookmark,
        BookmarkChecked,
        Spotify,
        Youtube,
        Bandcamp,
        Link
    },
    data: function () {
        return {
            shanty: undefined,
            details: undefined,
            bookmarked: false
        }
    },
    created() {
        this.shanty =  require('@/assets/shanties/shanties/' + this.filename + '.json');
        this.details = this.shanty.versions[this.versionID];
        this.isBookmarked();
        this.$store.commit('addToLastViewed', {id: this.versionID, filename:this.filename, shanty: this.details.name, credit: this.details.credit});
    },
    methods: {
        bookmark: function() {
            this.$store.commit('addBookmark', {id: this.versionID, filename:this.filename, shanty: this.details.name, credit: this.details.credit});
            this.isBookmarked();
        },
        removeBookmark: function() {
            this.$store.commit('removeBookmark', {id: this.versionID, filename:this.filename});
            this.isBookmarked()
            
        },
        isBookmarked: function() {
            this.bookmarked = Object.keys(this.$store.getters.getBookmarks).includes(this.filename + this.versionID)
        }
    },
}
</script>

<style lang="scss" scoped>
    .header {
        .top-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 1rem;

            .back-arrow {
                color: #00f6ff;
                text-decoration: none;
            }
        }
        .title {
            font-size: 1.5rem;
            text-align: center;
            padding: 1.5rem 16px .8rem 16px;
            font-weight: 300;
        }
        .credit {
            text-align: center;
        }
        .sources {
            margin: 1.5rem auto;
            display: flex;
            justify-content: space-evenly;

            .source-link {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #00f6ff;
                text-decoration: none;
            }
        }
    }
    .highlight {
        color: #00f6ff;
    }
    .lyrics-wrap {
        padding: .5rem;
        .title {
            font-size: 1.2rem;
            padding: .5rem;
            color: #00f6ff;     
        }
        .lyrics-card {
            background: #25004b;
            border-radius: 5px;
            padding: .5rem;
            margin-bottom: 2rem;

            .chorus, .verse {
                padding: 0.5rem 0;
                .line {
                    line-height: 1.2rem;
                }
                .chorus-indicator {
                    font-style: italic;
                    color: #00f6ff;
                }
            }
        }
    }
</style>